import { Provider } from 'react-redux';
import './App.css';
import LandingPage from './components/Screen/LandingPage';
import { store} from './components/Redux/store';
import { FaWhatsapp } from 'react-icons/fa';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WaitListModal from './components/Screen/WaitList';
import Main from './components/Screen/Main';

function App() {
  const openGoogle = () => {
    window.open('https://wa.link/whfcpo', '_blank');
  };

  

  return (
    <div className="pt-0 max-w-full h-auto relative">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Main/>} />
            <Route path='/home' element={<LandingPage/>} />
            <Route path='waitlist' element={<WaitListModal/>} />
            
          </Routes>
        </BrowserRouter>

      </Provider>

      {/* Floating WhatsApp Icon */}
      <div 
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',  // Changed 'left' to 'right'
          zIndex: 1000,
          cursor: 'pointer',
          backgroundColor: '#25D366',
          borderRadius: '50%',
          padding: '10px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }}
        onClick={openGoogle}
      >
        <FaWhatsapp color="white" size={30} />
      </div>
    </div>
  );
}

export default App;
