import React from 'react'
import Modal from '../common/Modal'

const Modals = () => {
    return (
        <div className='py-10 px-20 flex flex-col gap-y-10'>
            <Modal heading={"Who is a MASTRO?"} content={"A MASTRO is an individual who has mastered a specific talent, skill, or expertise and is willing to monetize it by offering sessions, packages, or courses to learners on our platform."} />
            {/* <Modal heading={"How do I monetize my talent on MASTROS?"} content={"On MASTROS, you can monetize your talent by creating and selling packages or sessions, setting competitive prices, and promoting your offerings to our global community of passionate learners."} /> */}
            <Modal heading={"What skills can I learn about on Talecto?"} content={"Talecto offers a wide range of skills, including creative, atheletic, technical, linguistic, logical, spatial, interpersonal and creative pursuits, catering to diverse learning interests and much more."} />
            <Modal heading={"Who can benefit from Talecto?"} content={"Individuals seeking to enhance their skills or talents, regardless of background, can benefit from the intelligent matching system that connects them with qualified coaches."} />
            <Modal heading={"Who can join MASTROS?"} content={"Anyone with a mastered talent, skill, or expertise can join MASTROS. Our platform welcomes experts from various fields, including music, art, writing, coding, cooking, and more."} />

            <Modal heading={"How do I find learners interested in my talent?"} content={"Our platform uses algorithms to connect you with passionate learners globally. You can also promote your profile and offerings through social media, referrals, and our partner networks."} />
            <Modal heading={"What's the revenue potential for MASTROS?"} content={"Your revenue potential on MASTROS is unlimited. Earn up to $1,000+ per session/package, depending on your expertise, pricing, and learner demand. Our top MASTROS earn significant income sharing their talents."} />

        </div>
    )
}

export default Modals
