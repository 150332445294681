import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import Logo from "../../assets/image 4.png";
import JoinBtn from '../Buttons/JoinBtn';
import { useDispatch } from 'react-redux';
import { changeRef } from '../Redux/action';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reference,setReference] = useState("NAV")
  const navigation=useNavigate()
  const dispatch=useDispatch()
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (event.target.id === 'modal') {
      setIsOpen(false);
    }
  };

  useEffect(()=>{
    dispatch(changeRef(reference))
  },[reference])

  return (
    <>
      <nav className='flex flex-row justify-between h-[90px] w-screen px-4 items-center'>
        {/* Logo */}
        <div className='cursor-pointer' onClick={()=>navigation("/")}>
          <img src={Logo} alt="Logo" height={67} width={63} />
        </div>

        {/* Hamburger Icon for Mobile (aligned right with margin) */}
        <div className='md:hidden ml-auto mr-4'> {/* Added right margin */}
          <button onClick={toggleSidebar}>
            <FaBars size={30} />
          </button>
        </div>

        {/* Desktop Navigation */}
        <div className='hidden md:flex flex-row gap-x-8 items-center ml-auto'>
          <div onClick={()=>setReference("VALUE")}  className='cursor-pointer'>Our Values</div>
          <div onClick={()=>setReference("TALENT")}   className='cursor-pointer'>Talents we offer</div>
          <div onClick={()=>setReference("BENIFIT")}   className='cursor-pointer'>Benefits</div>
          <div onClick={()=>setReference("FAQ")} className='cursor-pointer'>FAQs</div>
          {/* Join Button with reduced margin */}
          <div className='ml-4 mr-8'> {/* Reduced margin between FAQs and JoinBtn, added right margin */}
            <JoinBtn />
          </div>
        </div>
      </nav>

      {/* Sidebar Modal for Mobile */}
      {isOpen && (
        <div 
          id="modal"
          className='fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-start items-center z-50' 
          onClick={handleOutsideClick}
        >
          <div className='bg-white w-3/5 h-full rounded-r-lg p-8 relative'>
            <div className='flex flex-col items-start'>
              <div className='flex items-center mb-4'>
                <img src={Logo} alt="Logo" height={40} width={40} className="mr-2" /> 
                <h1 className='text-2xl font-bold'>Talecto</h1>
              </div>
            </div>

            <div className='flex flex-col mt-8'>
              <div onClick={()=>setReference("VALUE")} className='mb-4 cursor-pointer'>Our Values</div>
              <div onClick={()=>setReference("TALENT")}  className='mb-4 cursor-pointer'>Talents we offer</div>
              <div onClick={()=>setReference("BENIFIT")} className='mb-4 cursor-pointer'>Benefits</div>
              <div onClick={()=>setReference("FAQ")} className='mb-4 cursor-pointer'>FAQs</div>
              <div>
                <JoinBtn />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
