import React from 'react';
import NoActButton from '../Buttons/NoActButton';
import Chess from '../../assets/Chess1.png';
import Abacus from '../../assets/Abacus1.png';
import Archery from '../../assets/Archery.png';
import Boxing from '../../assets/Boxing1.png';
import Piano from '../../assets/Piano1.png';

const TalentDisplay = () => {
    const arr = [{name:"Chess",src:Chess}, {name:"Boxing",src:Boxing}, {name:"Piano",src:Piano}, {name:"Abacus",src:Abacus}, {name:"Archery",src:Archery}];

    return (
        <div className='mt-30 md:mt-30 flex flex-col px-4 md:px-20 gap-y-5'>
            <div>
                <NoActButton text={"Talents We Offer"} />
            </div>
            <div className='text-3xl md:text-4xl text-center md:text-left'>
                <span className='custom-gradient'>Explore</span>
                <span>{" "}</span>
                <span>the diverse range of talents you can start</span>
                <p className='custom-gradient'>mastering with Talecto.</p>
            </div>
            <div className='text-base md:text-lg text-center md:text-left'>
                <p>Our Mastros specialize in helping you develop unique skills that</p>
                <p>enhance your creativity, focus, confidence and strength</p>
            </div>
            <div className='flex flex-wrap justify-center md:justify-start gap-4 mt-5'>
                {arr.map((ele, index) => (
                    <div 
                        key={index} 
                        className={`flex-shrink-0 rounded-xl p-4 ${index % 2 === 0 ? 'bg-gradient-to-b from-[#F2EBFF] to-white' : 'bg-gradient-to-b from-[#F3E7D1] to-white'}`}>
                        <p className='text-3xl md:text-3xl font-semibold mb-2'>{ele.name}</p>
                        <img src={ele.src} alt={ele.src} className="w-36 mt-12 md:w-52 rounded-lg" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TalentDisplay;
