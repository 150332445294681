import React, { useState } from 'react'; 
import JoinBtn from '../Buttons/JoinBtn';
import RoleCTA from '../Buttons/RoleCTA';
import { useDispatch, useSelector } from 'react-redux';
import { database, ref, push, set } from '../../firebase'; // Import Firebase functions
import Select from 'react-select'; // For searchable country dropdown
import countryList from 'react-select-country-list'; // Country list for dropdown
import { toast, ToastContainer } from "react-toastify";
 
import "react-toastify/dist/ReactToastify.css";
import { changeModal } from '../Redux/action';
 
const HeroWaitlist = () => {
  const dispatch=useDispatch()
  const role = useSelector((state) => state.role);
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    talent: '',
    country: { label: 'India', value: 'IN' }, // Default country set to India
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState('');
  const options = countryList().getData(); // Get country list for dropdown

  // Regex patterns
  const regexPatterns = {
    name: /^[A-Za-z ]{3,}$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    phone: /^[0-9]{10}$/, // Only 10 digit phone numbers allowed
    talent: /^.{3,}$/,
  };

  const resetFormData = () => {
    setFormData(initialFormData); // Reset form data to initial values
    setErrors({}); // Clear any errors
    setSubmitStatus(''); // Clear submit status
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the error for the input being typed into
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    }
  };

  // Handle country select changes
  const handleCountryChange = (selectedCountry) => {
    setFormData({
      ...formData,
      country: selectedCountry,
    });

    // Clear error when the country is selected
    if (errors.country) {
      setErrors((prevErrors) => ({ ...prevErrors, country: undefined }));
    }
  };

  // Validate inputs
  const validateInputs = () => {
    const newErrors = {};
    for (const field in formData) {
      if (!formData[field] && field !== 'country') {
        newErrors[field] = 'This field is required.';
      } else if (regexPatterns[field] && !regexPatterns[field].test(formData[field])) {
        newErrors[field] = `Invalid ${field}.`;
      }
    }
    return newErrors;
  };

  // Submit form data to Firebase with a unique key
  const handleSubmit = async () => {
    const newErrors = validateInputs();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setSubmitStatus('fail');
      toast.error("Error!! Please verify & Try again")
      return;
    }

    try {
      const waitlistRef = ref(database, 'waitlist'); // Reference to 'waitlist' in Firebase
      const newWaitlistRef = push(waitlistRef); // Generate a unique key
      await set(newWaitlistRef, {
        ...formData,
        role,
      });
      setSubmitStatus('success');
      toast.success("Successfully Joined Waitlist");
      setTimeout(()=>{
        dispatch(changeModal("false"))
      },1000)
      resetFormData()
    } catch (error) {
      console.error('Firebase error:', error);
      setSubmitStatus('fail'); // Handle failure case if needed
      toast.error("Error!! Please verify & Try again")
    }
  };

  // Render input fields with validation
  const renderInput = (name, placeholder, type = 'text') => (
    <div className={`input-wrapper flex-grow bg-white rounded-lg p-3 border ${errors[name] ? 'border-red-500' : 'border-gray-300'} relative`}>
      {!errors[name] && formData[name] && regexPatterns[name]?.test(formData[name]) && (
        <div className="">
          <div className="hero-waitlist-tick w-6 h-6 rounded-full flex justify-center items-center bg-[#A578FF] absolute left-3 top-1/2 transform -translate-y-1/2">
          <span className="text-white">✓</span>
        </div>
        </div>
      )}
      <input
        type={type}
        name={name}
        className={`hero-waitlist-input w-full bg-transparent placeholder-grey text-black ${!regexPatterns[name]?.test(formData[name]) ? 'pl-0' : 'pl-10'} border-none outline-none`} // Removed black border
        placeholder={placeholder}
        value={formData[name]}
        onChange={handleInputChange}
      />
      {errors[name] && <div className="text-red-500 text-sm">{errors[name]}</div>} {/* Show error message if exists */}
      
    </div>
  );

  return (
    
    <>
        {/* Form container */}
        
        <div className="hero-waitlist-form-container w-full max-w-lg bg-gradient-to-b from-[#F2EBFF] to-[#F2EBFF] rounded-xl border border-solid border-[#ECECEC] p-6 mt-6 relative">
          

          <div className="flex justify-start mb-4 text-white">
            <RoleCTA />
          </div>

          <div className="hero-waitlist-input-group flex flex-col gap-4">
            {renderInput('name', 'Name')}
            {renderInput('email', 'Email', 'email')}
            
            {/* Wrapper for country select and phone input */}
            <div className="bg-white py-2 pr-2 rounded-lg flex gap-0 border border-gray-300">
              {/* Country select (36% width) */}
              <div className="w-1/3 mr-2 flex items-center"> {/* Changed to 1/2 width */}
                <Select
                  options={options}
                  value={formData.country}
                  onChange={handleCountryChange}
                  isSearchable={true}
                  className="country-select"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      marginLeft:"10px",
                      borderColor: errors.country ? 'red' : provided.borderColor,
                      height: '100%', // Match height with input
                    }),
                  }}
                />
              </div>
              
              {/* Phone number input (64% width) */}
              
                {renderInput('phone', 'Phone Number', 'tel')}
              
            </div>

            {renderInput('talent', 'Talent')}
          </div>
              <ToastContainer/>
          <div className="hero-waitlist-join-btn mt-6 w-full">
            <button onClick={handleSubmit} className="w-full">
              <JoinBtn color="black" text="white" />
            </button>
          </div>

          {/* Success message */}
          {submitStatus === 'success' && (
            <div className="mt-4 p-4 bg-green-200 text-green-800 rounded-lg">
              Your details have been submitted successfully!
            </div>
          )}
        </div>
      </>
  );
};

export default HeroWaitlist;
