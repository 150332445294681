import React from 'react';
import JoinBtn from '../Buttons/JoinBtn';
import DeviceMockup3 from '../../assets/DeviceMockup3.png';

const SecondMockup = () => {
    return (
        <div className='mx-4 md:mx-20 mt-20 bg-second-gradient h-auto rounded-xl flex flex-col md:flex-row'>
            <div className='flex flex-col justify-center gap-y-10 mx-4 md:mx-10 p-5'>
                <div className='text-white text-3xl md:text-4xl'>
                    <p>“Be the first to get access and</p>
                    <p>start learning as soon as we</p>
                    <p>launch”</p>
                </div>
                <div className='w-fit'>
                    <JoinBtn text={"black"} color={"white"} />
                </div>
            </div>
            <div className='hidden md:flex md:mt-auto md:ml-10 overflow-hidden flex items-end '> {/* Added -mb-10 */}
                <img src={DeviceMockup3} alt="Device" className='max-w-full h-auto md:ml-1 pt-10 ml-28' />
            </div>
        </div>
    );
}

export default SecondMockup;
