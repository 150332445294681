import React, { useState } from 'react'
import { FiPlus } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";

const Modal = ({ heading, content }) => {

    const [open, setOpen] = useState(false);

    const openHandler = () => {
        setOpen(prev => !prev);
    }

    return (
        <div className={`transition-all duration-500 ${open ? ("bg-grey-gradient min-h-40 ") : ("min-h-20")} rounded-xl border-2 border-gray-100 flex flex-col gap-y-5 w-full`} onClick={openHandler}>
            <div className='w-full flex justify-between mt-5'>
                <div className={`ml-10 font-semibold`}>
                    {heading}
                </div>
                <div className={`w-fit bg-[#A578FF] text-white p-1 rounded-full mr-10`}>
                    {
                        !open ? (<FiPlus />) : (<RxCross2/>)
                    }
                </div>
            </div>
            <div className={`${open ? "" : "hidden"} text-[#5C5C5C] mx-10 mb-5 w-1/2`}>{content}</div>
        </div>
    )
}

export default Modal
