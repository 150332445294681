import React from 'react'
import DeviceMockup2 from "../../assets/DeviceMockup2.png"

const DeviceMockup = () => {
  return (
    <div className="relative w-full h-full">
      <img
        src={DeviceMockup2}
        className="h-full w-full object-cover sm:clip-bottom md:clip-bottom"
        alt="device mockup"
      />
    </div>
  )
}

export default DeviceMockup;
