// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, set } from "firebase/database";

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDIBto7KhZ_hwm7GVJEe_dijMdyIj7tpbo",
    authDomain: "talecto-landing-page.firebaseapp.com",
    databaseURL: "https://talecto-landing-page-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "talecto-landing-page",
    storageBucket: "talecto-landing-page.appspot.com",
    messagingSenderId: "148596385923",
    appId: "1:148596385923:web:a87226ed2eef73335553b6",
    measurementId: "G-C4XW2MBM7P"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Export the necessary functions
export { database, ref, push, set };
