export function reducer(state={role:"Learners"},action){
    switch(action.type){
        case 'CHANGE':
            return {...state,role:state.role==="Learners"?"Mastros":"Learners"}
        default:
            return state
    }
}

export function modalReducer(state = { site: true }, action) {
    switch (action.type) {
      case "true":
        return { ...state, site: true };
      case "false":
        return { ...state, site: false };
      default:
        return state;
    }
  }


export function reducer1(state={reference:"NAV"},action){
    switch(action.type){
        case 'VALUE':
            return {...state,reference:"VALUE"}
        case 'TALENT':
            return {...state,reference:"TALENT"}
        case 'BENIFIT':
            return {...state,reference:"BENIFIT"}
        case 'FAQ':
            return {...state,reference:"FAQ"}
        default:
            return state
    }
}