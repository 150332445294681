import React from 'react';

const NoActButton = ({ text }) => {
  return (
    <div className='border-2 border-gray-300 w-fit px-4 py-2 rounded-full bg-black text-white cursor-pointer'>
      {text}
    </div>
  );
}

export default NoActButton;
