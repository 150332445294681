import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changeRole } from '../Redux/action';

const RoleCTA = () => {

    const [role, setRole] = useState("Learners");

    const dispatch=useDispatch()

    const clickHandler = (role) => {
        setRole(role);
        console.log(role)
        dispatch(changeRole())
    }
    return (
        <div className='flex flex-row gap-x-2 bg-black w-[196px] h-[42px] justify-around items-center rounded-full cursor-pointer'>
            <div className={` transition-all duration-150 ml-1 px-4 py-1 ${role === "Learners" ? "bg-white text-black rounded-full" : ""}`}
                onClick={() => clickHandler("Learners")}>Learners</div>
            <div className={`transition-all duration-150 mr-1 px-4 py-1 ${role === "Mastros" ? "bg-white text-black rounded-full" : ""}`} onClick={() => clickHandler("Mastros")}>Mastros</div>
        </div>
    )
}

export default RoleCTA
