import React from "react";
import { HiXCircle, HiCheckCircle } from "react-icons/hi";
import NoActButton from "../Buttons/NoActButton";

const Diff = () => {
  const arr=[{problem:"Difficulty Finding the Right Coach - Many learners struggle to find mentors who truly understand their specific needs and learning objectives, making the search for the right guidance frustrating.",solution:"Personalized Matches – Talecto connects you with coaches who align with your skill level and learning style, ensuring a tailored and effective learning experience."},{problem:"Limited Access to Quality Coaching - Geographical barriers often restrict access to high-quality coaching, leaving many without the support they need for growth.",solution:"Global Access – Talecto bridges these gaps by enabling connections with expert coaches from around the world, providing opportunities for quality learning regardless of location."},{problem:"High Cost of Coaching - Professional coaching services can be prohibitively expensive, making it difficult for many to invest in their development.",solution:"Affordable Coaching – Talecto offers flexible pricing plans and budget-friendly coaching options, ensuring that high-quality guidance is accessible to everyone."}]
  return (
    <div className="flex flex-col md:flex-row gap-10 w-full mt-10">
      <div className="flex-1 h-auto bg-gradient-to-b from-orange-50 to-white">
        <div className="mx-10 my-5">
          <NoActButton text={"Problems"} />
        </div>
        {arr.map((ele, index) => (
          <div className="p-4 m-5 bg-white" key={index}>
            <span className="flex gap-4">
              <HiXCircle size={40} color="red" />
              {ele.problem}
            </span>
          </div>
        ))}
      </div>
      
      {/* Solutions */}
      <div className="flex-1 h-auto bg-gradient-to-b from-purple-100 to-white">
        <div className="mx-10 my-5">
          <NoActButton text={"Solutions"} />
        </div>
        {arr.map((ele, index) => (
          <div className="p-4 m-5 bg-white" key={index}>
            <span className="flex gap-4">
              <HiCheckCircle size={40} color="#A578FF" />
              {ele.solution}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Diff;
