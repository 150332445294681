import React from 'react';
import Teacher from '../../assets/Teacher.png';
import Card from '../../assets/Cards.png';
import Skill from '../../assets/Skill.png';

const Dual = () => {
    return (
        <div className='px-4 md:px-20 mt-10 md:mt-10 flex flex-col w-full'>
            {/* First Row */}
            <div className='flex flex-col md:flex-row w-full gap-10 md:gap-20'>
                <div className='w-full md:w-1/2 flex justify-center'>
                
                    <div className='relative'>
                        
                        {/* Teacher Image */}
                        <img src={Teacher} alt="Teacher" className='w-full h-auto' />
                        {/* Card Image - Half Overlapping on Teacher Image */}
                        
                        <div className='absolute z-10 right-[-25%] top-3/4 transform -translate-y-1/2'>
                            <img src={Card} alt="Card" className='w-3/4 h-auto' />
                        </div>
                        
                    </div>
                    
                </div>
                <div className='w-full md:w-1/2 flex flex-col justify-center gap-y-5 mb-10 '>
                    <div className='text-2xl md:text-3xl font-semibold'>Tailored Coaching</div>
                    <div className='text-base md:text-lg'>Work one-on-one with an expert coach to identify your unique strengths and unlock your full potential.</div>
                </div>
                
            </div>

            {/* Second Row */}
            <div className='flex flex-col md:flex-row w-full gap-10 md:gap-20 mt-20'>
                <div className='w-full md:w-1/2 flex flex-col justify-center gap-y-5 relative'>
                    <div className='text-2xl md:text-3xl font-semibold'>Skill-Building Resources</div>
                    <div className='text-base md:text-lg'>Gain access to a curated library of workshops, courses, and tools to enhance your capabilities.</div>
                    {/* Two Card Images below the text */}
                    <div className='flex justify-center md:justify-start mt-20'>
                        <img src={Card} alt="Card" className='w-1/2 h-auto' />
                        <img src={Card} alt="Card" className='w-1/2 h-auto ml-4' />
                    </div>
                </div>

                <div className='w-full md:w-1/2 flex justify-center'>
                    <div className='relative'>
                        {/* Teacher Image */}
                        <img src={Skill} alt="Teacher" className='w-full h-auto' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dual;
