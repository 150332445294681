import React from 'react';
import NoActButton from '../Buttons/NoActButton';

const TextConn = () => {
    return (
        <div className='mt-20 md:mt-20 px-4 md:px-20 flex flex-col gap-y-5'>
            <div>
                <NoActButton text={"Benefits"} />
            </div>
            <div className='flex flex-col gap-3'>
                <div className='text-3xl md:text-5xl lg:text-5xl mb-2'>
                    <span className='custom-gradient'>Discover{" "}</span>
                    <span>new talents and{" "}</span>
                    <span>refine your skills with the</span>
                    <br/>
                    <span className='custom-gradient'>guidance of accomplished professionals.</span>
                </div>
                <div className='text-base md:text-xl mt-1'>
                    <p>Talecto connects you with experts who are passionate about helping you succeed.</p>
                </div>
            </div>
        </div>
    );
}

export default TextConn;
