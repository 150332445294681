import React from 'react';
import Personalized from '../../assets/Personalized.png';
import Expert from '../../assets/Expert.png';

const GradCard = () => {
    return (
        <div className='flex flex-col md:flex-row h-auto mt-20 md:mt-40 px-4 md:px-20 w-full gap-10'>
            {/* First Card */}
            <div className='flex-1 flex flex-col justify-start rounded-lg gap-y-3 pb-5 px-5'>
                {/* Image at the top */}
                <div className='flex justify-center'>
                    <img src={Personalized} alt="Personalized" className='w-auto h-auto' />
                </div>
                {/* Text Content */}
                <div className='text-2xl sm:text-2xl font-semibold mt-5 text-left'>Personalized Learning</div>
                <div className='text-sm sm:text-lg text-left'>Find the right mentor who matches your goals and style.</div>
            </div>

            {/* Second Card */}
            <div className='flex-1 flex flex-col justify-start rounded-lg gap-y-3 pb-5 px-5'>
                {/* Image at the top */}
                <div className='flex justify-center'>
                    <img src={Expert} alt="Personalized" className='w-auto h-auto' />
                </div>
                {/* Text Content */}
                <div className='text-2xl sm:text-2xl font-semibold mt-5 text-left'>
                    <p>Expert-Backed</p>
                    <p>Recommendations</p>
                </div>
                <div className='text-sm sm:text-lg text-left'>Find the right mentor who matches your goals and style.</div>
            </div>
        </div>
    );
}

export default GradCard;
