import React from 'react';
import JoinBtn from '../Buttons/JoinBtn';
import DeviceMockup from './DeviceMockup';
import RoleCTA from '../Buttons/RoleCTA';
import Person1 from "../../assets/Person1.png";
import Person2 from "../../assets/Person2.png";
import Person3 from "../../assets/Person3.png";

const Hero = () => {
  return (
    <div className="px-0 md:px-4 lg:px-8"> {/* Removed horizontal padding for small screens */}
      <section className="w-full h-auto md:h-screen gradient rounded-3xl">
        <div
          id="wrapper"
          className="flex flex-col md:flex-row justify-between w-full h-full mb-5"
        >
          {/* Left Content */}
          <div className="mt-8 md:mt-20 text-white p-4 flex-1 relative">
            <div className="mx-0 md:mx-10 lg:mx-20 max-w-full"> {/* Adjusted margins for small screens */}
              {/* Call to Action Button on Top */}
              <div className="mb-4 md:mb-6">
                <RoleCTA />
              </div>

              {/* Image Group with Horizontal Alignment */}
              <div className="flex items-center mb-4 md:mb-8">
                <div className="relative flex">
                  <img
                    className="relative w-[10vw] md:w-[4vw] lg:w-[3vw] xl:w-[2.5vw] z-30 max-w-full"
                    srcSet={`${Person1} 300w, ${Person1} 600w, ${Person1} 900w`}
                    sizes="(max-width: 640px) 10vw, (max-width: 768px) 4vw, (min-width: 769px) 3vw"
                    src={Person1}
                    alt="Person 1"
                    loading="lazy"
                  />
                  <img
                    className="relative w-[10vw] md:w-[4vw] lg:w-[3vw] xl:w-[2.5vw] left-[-3vw] md:left-[-1vw] lg:left-[-0.5vw] z-20 max-w-full"
                    srcSet={`${Person2} 300w, ${Person2} 600w, ${Person2} 900w`}
                    sizes="(max-width: 640px) 10vw, (max-width: 768px) 4vw, (min-width: 769px) 3vw"
                    src={Person2}
                    alt="Person 2"
                    loading="lazy"
                  />
                  <img
                    className="relative w-[10vw] md:w-[4vw] lg:w-[3vw] xl:w-[2.5vw] left-[-6vw] md:left-[-3vw] lg:left-[-1.5vw] z-10 max-w-full"
                    srcSet={`${Person3} 300w, ${Person3} 600w, ${Person3} 900w`}
                    sizes="(max-width: 640px) 10vw, (max-width: 768px) 4vw, (min-width: 769px) 3vw"
                    src={Person3}
                    alt="Person 3"
                    loading="lazy"
                  />
                </div>
                <div className="ml-4 text-sm md:text-lg lg:text-xl text-black">
                  Become the top 1%
                </div>
              </div>

              {/* Headline */}
              <h2 className="text-3xl md:text-5xl lg:text-6xl my-4 md:my-8 text-black">
                <span className="custom-gradient">Discover & Enhance</span> Your Hidden Talents with {""}
                <span className="custom-gradient">Our Expert Coaching</span>.
              </h2>

              {/* Description Text */}
              <p className="text-black text-sm md:text-base lg:text-lg mb-4">
              Join Talecto to connect with top coaches who can help unlock your full potential. Start your journey of growth and mastery today!
              </p>

              {/* Join Button */}
              <div className="flex flex-row mt-4 md:mt-8">
                <div className='w-1/2'>
                  <JoinBtn color={"black"} text={"white"} />
                </div>
                
              </div>
            </div>
          </div>

          {/* Right Side: Device Mockup */}
          <div className="flex-1 mt-10 md:mt-0 flex justify-center items-center p-0 md:p-4">
            <DeviceMockup />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
