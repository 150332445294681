// store.js
import { createStore, combineReducers } from "redux";
import { modalReducer, reducer, reducer1} from "./reducer"; 

const rootReducer = combineReducers({
  role: reducer,
  reference: reducer1,
  site:modalReducer
});

export const store = createStore(rootReducer);
