import React, { useState, useEffect } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaArrowUp } from 'react-icons/fa'; // Import the up arrow icon
import LOGO from '../../assets/image 4.png';
import JoinBtn from '../Buttons/JoinBtn';
import { useDispatch } from 'react-redux';
import { changeRef } from '../Redux/action';

const Footer = () => {
  const [reference, setReference] = useState("NAV");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeRef(reference));
  }, [reference]);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-black text-white w-full py-8 mt-10">
      <div className="container mx-auto flex justify-between">
        {/* Left Column */}
        <div className="flex flex-col space-y-4 ml-8">
          {/* Logo */}
          <div className="logo">
            <img src={LOGO} alt="Logo" className="w-22" />
          </div>
          {/* Quick Links */}
          <div className="text-xl font-semibold">Quick Links</div>
          <div onClick={() => setReference("VALUE")} className="text-sm cursor-pointer">Our Values</div>
          <div onClick={() => setReference("TALENT")} className="text-sm cursor-pointer">Talents We Offer</div>
          <div onClick={() => setReference("BENIFIT")} className="text-sm cursor-pointer">Benefits</div>
          <div onClick={() => setReference("FAQ")} className="text-sm cursor-pointer">FAQ</div>
        </div>

        {/* Right Column */}
        <div className="flex flex-col items-end space-y-4 mr-8">
          {/* Join Button and Join Us text */}
          <div className="flex flex-col items-start space-y-2">
            {/* Join Button */}
            <div><JoinBtn /></div>
            {/* Join Us (in next row, left-aligned with the button) */}
            <div className="text-sm ml-4 pt-10">Join Us</div>
          </div>

          {/* Social Icons with links (in a single row) */}
          <div className="flex space-x-12">
            <a href="https://www.facebook.com/profile.php?id=61559914054297" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="text-xl cursor-pointer hover:text-gray-400" />
            </a>
            <a href="https://www.instagram.com/talecto.official/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-xl cursor-pointer hover:text-gray-400" />
            </a>
            <a href="https://www.linkedin.com/company/talecto-pvt-ltd/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="text-xl cursor-pointer hover:text-gray-400" />
            </a>
          </div>

          {/* Scroll to Top Button */}
          <div 
            onClick={scrollToTop} 
            className="mt-4 cursor-pointer flex items-center justify-center bg-white rounded-full w-10 h-10" // Add styles for white circle
          >
            <FaArrowUp className="text-black" /> {/* Up arrow icon */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
