import React, { useState, useEffect } from 'react';
import './Main.css';
import LandingPage from './LandingPage';
import HeroWaitlist from '../Home/HeroWaitlist';
import { useDispatch, useSelector } from 'react-redux';
import { changeModal } from '../Redux/action';

const Main = () => {
  // Access the correct "site" property from Redux state
  const isModalVisible = useSelector((state) => state.site.site); 
  const [isModalOpen, setIsModalOpen] = useState(isModalVisible);

  const dispatch = useDispatch();

  // Sync state with Redux state whenever it changes
  useEffect(() => {
    setIsModalOpen(isModalVisible);
  }, [isModalVisible]);

  // Function to open the modal (dispatch action to open)
  const handleOpenModal = () => {
    dispatch(changeModal("true"));
  };

  // Function to close the modal (dispatch action to close)
  const handleCloseModal = () => {
    dispatch(changeModal("false"));
  };

  // Handle click outside modal to close it (optional)
  const handleOutsideClick = (event) => {
    if (event.target.className === 'modal-overlay') {
      handleCloseModal();
    }
  };

  return (
    <>
      <LandingPage onOpenModal={handleOpenModal} />

      {isModalOpen && (
        <div className="modal">
          <div className="modal-overlay" onClick={handleOutsideClick}></div>
          <div className="modal-content animate">
            {/* Close button with cross icon */}
            <div 
              className="close-btn absolute top-3 right-3 w-8 h-8 bg-black rounded-full flex justify-center items-center cursor-pointer"
              onClick={handleCloseModal}
            >
              <span className="text-white text-xl">✕</span>
            </div>
            <HeroWaitlist onClose={handleCloseModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default Main;
