import React from 'react'; 
import { IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeModal } from '../Redux/action';

const JoinBtn = ({ color, text }) => {
    const navigation=useNavigate()
    
    const dispatch=useDispatch()
    return (
        <div onClick={()=>dispatch(changeModal("true"))} className={`flex items-center gap-2 md:gap-4 bg-${color} rounded-3xl pl-4 pr-2 py-1 border border-gray-300 cursor-pointer transition duration-200 hover:shadow-lg`}>
            <div className={`text-${text} text-sm md:text-base p-1 flex-grow`}>
                Join the Waitlist
            </div>
            <div className={`flex items-center justify-center bg-custom-gradient rounded-full p-1`}>
                <IoIosArrowRoundForward className={`text-${text}`} />
            </div>
        </div>
    );
}

export default JoinBtn;
