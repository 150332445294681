import React, { useEffect, useRef } from 'react';
import Navbar from "../common/Navbar";
import ProblemSolve from '../Home/ProblemSolve';
import TalentDisplay from '../Home/TalentDisplay';
import TextConn from '../Home/TextConn';
import TextConn1 from '../Home/TextConn1';
import Dual from '../Home/Dual';
import GradCard from '../Home/GradCard';
import SecondMockup from '../Home/SecondMockup';
import Modals from '../Home/Modals';
import HeroWaitlist from '../Home/HeroWaitlist';
import Footer from '../Home/Footer';
import { useSelector, useDispatch } from 'react-redux';
 // Replace with your actual action for toggling modal

const WaitList = () => {
  const reference = useSelector((state) => state.reference.reference);
  const dispatch = useDispatch();

  // Create refs for each component
  const navRef = useRef(null);
  const heroRef = useRef(null);
  const talentDisplayRef = useRef(null);
  const textConnRef = useRef(null);
  const textConn1Ref = useRef(null);
  const problemSolveRef = useRef(null);

  
  

 
  // Scroll to the corresponding component based on reference state
  const scrollToComponent = () => {
    switch (reference) {
      case "NAV":
        navRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "VALUE":
        heroRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "TALENT":
        talentDisplayRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "BENIFIT":
        textConnRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "FAQ":
        textConn1Ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    scrollToComponent(); // Call scroll function when reference changes
  }, [reference]);

  return (
    <div className="modal-overlay">
      <div className="modal-content" >
        <div ref={navRef}><Navbar /></div>
        <div ref={heroRef}><HeroWaitlist /></div>
        <div ref={problemSolveRef}><ProblemSolve /></div>
        <div ref={talentDisplayRef}><TalentDisplay /></div>
        <div ref={textConnRef}><TextConn /></div>
        <Dual />
        <GradCard />
        <SecondMockup />
        <div ref={textConn1Ref}><TextConn1 /></div>
        <Modals />
        <Footer />
      </div>
    </div>
  );
};

export default WaitList;
