import React from 'react';
import Diff from './Diff';
import JoinBtn from "../Buttons/JoinBtn";

const ProblemSolve = () => {
  return (
    <div className='mt-20 mb-10'> {/* Margin for spacing */}
      <div className="flex flex-col w-full items-start justify-start px-4 md:px-20">
        <div className="text-3xl md:text-4xl">
          <span className="custom-gradient">Your learning</span> journey needs{" "}
           more than just<br className="hidden md:block" />a quick start—It needs{" "}
          <span className="custom-gradient">
            expert guidance <br className="hidden md:block" /> that transforms your talent into greatness.
          </span>
        </div>
        <div className="mt-5 w-full text-lg flex flex-col md:flex-row justify-between items-center">
          <p className="text-center md:text-left">
            Talecto connects you with skilled mentors focused on empowering your growth.
          </p>
          <div className='mt-4 md:mt-0 w-1/5'>
            <JoinBtn color={"white"} text={"black"} />
          </div>
        </div>
        <div className='w-full h-auto mt-10'>
          <Diff />
        </div>
      </div>
    </div>
  );
}

export default ProblemSolve;
