import React, { useEffect, useRef } from 'react';
import Navbar from "../../components/common/Navbar";
import Hero from "../../components/Home/Hero";
import ProblemSolve from '../../components/Home/ProblemSolve';
import TalentDisplay from '../../components/Home/TalentDisplay';
import TextConn from '../../components/Home/TextConn';
import TextConn1 from '../../components/Home/TextConn1';
import Dual from '../../components/Home/Dual';
import GradCard from '../../components/Home/GradCard';
import SecondMockup from '../../components/Home/SecondMockup';
import Modals from '../../components/Home/Modals';
import Footer from '../Home/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { changeRef } from '../Redux/action';
import WaitListModal from './WaitList';

const LandingPage = () => {
  const reference = useSelector((state) => state.reference.reference); // reference can be VALUE, TALENT, BENIFIT, FAQ
  const dispatch=useDispatch()
  const isModalVisible=useSelector((state)=>state.site)
  
  useEffect(()=>{
    console.log(isModalVisible)
  },[isModalVisible])
  // Create refs for each component
  const navRef = useRef(null)
  const heroRef = useRef(null);
  const talentDisplayRef = useRef(null);
  const textConnRef = useRef(null);
  const textConn1Ref = useRef(null);

  // Scroll to the corresponding component based on reference state
  const scrollToComponent = () => {
    switch (reference) {
      case "NAV":
        navRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        
        break;
      case "VALUE":
        heroRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        
        break;
      case "TALENT":
        talentDisplayRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "BENIFIT":
        textConnRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "FAQ":
        textConn1Ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      default:
        break;
    }

    
  };

  useEffect(() => {
    scrollToComponent(); // Call scroll function when reference changes
  }, [reference]);

  return (
    <>
      
      <div ref={navRef}><Navbar /></div>
      {/* Assign refs directly to components for better scrolling */}
      <div ref={heroRef}><Hero /></div>
      <ProblemSolve />
      <div ref={talentDisplayRef}><TalentDisplay /></div>
      <div ref={textConnRef}><TextConn /></div>
      <Dual />
      <GradCard />
      <SecondMockup />
      <div ref={textConn1Ref}><TextConn1 /></div>
      <Modals />
      <Footer />
      
    </>
  );
}

export default LandingPage;
